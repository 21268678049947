<template>
  <div class="flex row justify-start q-py-sm notification-card">
    <header class="header col-12" v-scroll-fire="viewCount">
      <!-- <UserPhoto :idUser="post.userId" /> -->
      <div class="avatar-notification">
        <q-icon
          v-if="post.category_image_path == null"
          name="account_circle"
          size="35px"
        />
        <q-img
          v-else
          width="100%"
          height="100%"
          :src="url_aws_bucket + post.category_image_path"
          spinner-color="white"
        >
          <template v-slot:error>
            <q-icon class="avatar" name="account_circle" size="35px" />
          </template>
        </q-img>
      </div>
      <div class="title-notification">
        <h6>
          {{ post.title }}
        </h6>
        <small>
          <b
            >postado por
            <b class="text-default-pink">{{ post.name }} </b>
            |</b
          >
          <b v-if="post.category_description != null"> Categoria:</b>
          <b class="text-default-pink">{{ post.category_description }} -</b>
          {{ datePost }}
        </small>
      </div>
      <div class="flex justify-end">
        <q-btn
          v-if="post.lightning_test_status"
          round
          flat
          dense
          class="q-px-none"
          size="md"
          :to="{
            name: 'lighting-test-answer',
            query: { id: post.lightning_test_id, concluded: false },
          }"
          color="default-pink"
          title="Post com teste relâmpago"
        >
          <q-icon size="xs" dense class="material-icons" name="bolt" />
        </q-btn>
        <q-btn
          v-if="btnFixed"
          round
          flat
          dense
          class="q-px-none"
          size="md"
          @click="showDatePicker()"
          color="default-pink"
          title="Fixar"
        >
          <q-icon
            size="xs"
            dense
            :class="
              post.is_fixed ? 'material-icons' : 'material-icons-outlined'
            "
            name="push_pin"
          />
        </q-btn>
        <q-btn
          round
          flat
          dense
          class="q-px-none"
          size="md"
          @click="$emit('favoritePost', post)"
          color="default-pink"
          title="Favoritar"
        >
          <q-icon
            v-if="post.is_favorite"
            size="xs"
            class="material-icons-outlined"
            name="star"
          />
          <q-icon
            v-else
            size="xs"
            class="material-icons-outlined"
            name="star_border"
          />
        </q-btn>
      </div>
      <div class="flex justify-end" v-show="user.role_id !== 3">
        <q-btn size="md" class="q-px-sm" color="default-pink" flat title="Mais">
          <q-icon size="sm" class="material-icons-outlined" name="more_horiz" />
          <q-menu>
            <q-list style="min-width: 100px">
              <q-item
                clickable
                v-close-popup
                @click="$emit('disabled', post)"
                v-if="validateModifyPost(post)"
              >
                <q-item-section avatar>
                  <q-icon
                    size="sm"
                    color="default-pink"
                    class="material-icons-outlined"
                    name="delete"
                  />
                </q-item-section>
                <q-item-section> Remover </q-item-section>
              </q-item>
              <q-item
                clickable
                v-close-popup
                @click="$emit('edit', post)"
                v-if="validateModifyPost(post)"
              >
                <q-item-section avatar>
                  <q-icon
                    size="sm"
                    color="default-pink"
                    class="material-icons-outlined"
                    name="edit"
                  />
                </q-item-section>
                <q-item-section> Editar </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="analytics">
                <q-item-section avatar>
                  <q-icon
                    size="sm"
                    color="default-pink"
                    class="material-icons-outlined"
                    name="analytics"
                  ></q-icon>
                </q-item-section>
                <q-item-section>Ver estatísticas</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </header>
    <div
      class="description col-12"
      v-html="addDescriptionPost(post.description)"
    ></div>
    <div class="col-12 flex justify-end row midia-box">
      <div
        class="col-12 row flex relative-position overflow-hidden justify-center full-width"
      >
        <CarouselPosts
          v-if="
            post.files.length > 0 ||
            post.link_live != null ||
            post.link_live != 'null' ||
            post.is_survey
          "
          :post="post"
        />
      </div>
    </div>

    <div class="col-12 row full-height">
      <div class="row col-12 flex justify-end">
        <div class="row col-12 flex items-baseline">
          <div class="flex items-start q-mr-md">
            <label
              class="text-bold text-default-pink q-pa-xs q-mx-xs"
              style="font-size: 14px"
              >{{ post.likes.toString() }}</label
            >
            <q-icon
              color="default-pink"
              size="1.4rem"
              style="cursor: pointer"
              :name="post.isLiked ? 'thumb_up_alt' : 'thumb_up_off_alt'"
              @click="addLike(post)"
            />
          </div>
          <div class="flex items-start q-mr-md">
            <div class="cursor-pointer" @click="showComents">
              <label
                class="text-bold text-default-pink q-pa-xs q-mx-xs"
                style="font-size: 14px"
                >{{ post.commentsQuantity }}</label
              >
              <q-icon
                color="default-pink"
                size="1.4rem"
                style="cursor: pointer"
                name="o_chat_bubble"
              />
            </div>
          </div>
          <div class="flex items-start">
            <div>
              <label
                class="text-bold text-default-pink q-pa-xs q-mx-xs"
                style="font-size: 14px"
              >
                {{ post?.number_total_views }}</label
              >
              <q-icon
                color="default-pink"
                size="1.4rem"
                name="remove_red_eye"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="q-ml-md button-coments" v-if="isShowComents">
      <FilterButtons
        :listItens="filterListItens"
        @selectItem="getItemSelected"
      />
    </div>
    <div class="section-coments" v-show="isShowComents">
      <keep-alive>
        <component
          :is="isShowComents ? 'SectionComents' : ''"
          :coments="post.comments"
          :postId="post.postId"
          :awnser="AwnserSeleceted"
        ></component>
      </keep-alive>
    </div>
  </div>
  <q-dialog v-model="isShowDatePicker">
    <q-card class="my-card q-pt-md">
      <label>Selecione até que dia o post ficará fixado</label>
      <div class="flex row justify-space-around cards-date">
        <q-date
          v-model="datePostFixed"
          minimal
          :options="
            (date) => {
              return date >= '2022/06/15';
            }
          "
          color="default-pink"
        />
        <q-time
          v-model="datePostFixed"
          mask="YYYY-MM-DD HH:mm"
          color="default-pink"
        />
        <q-separator />
      </div>

      <q-card-actions align="right">
        <q-btn v-close-popup flat color="default-pink" label="Cancelar" />
        <q-btn
          v-close-popup
          color="default-pink"
          @click="$emit('fixPost', post, datePostFixed)"
          label="Confirmar"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <DialogAnalytics
    v-if="visibleAnalytics"
    :visibleAnalytics="visibleAnalytics"
    :postId="post.postId"
    :commentsQuantity="post.commentsQuantity"
    :likes="post.likes"
    @closeDialog="() => (visibleAnalytics = false)"
  />
</template>

<script>
//----COMPONENTS
import FilterButtons from "@/components/FilterButtons.vue";
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import SectionComents from "@/components/Feed/post/comments/SectionComents.vue";
import CarouselPosts from "@/components/Feed/post/carosel/CarouselPosts.vue";
import UserPhoto from "@/components/shared/card/UserPhoto.vue";
import DialogAnalytics from "../dialogs/DialogAnalytics.vue";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";
import { ENV } from "@/utils/env";
import UpdateXpUser from "@/utils/UpdateXpUser";

//----SERVICES
import PostDataServices from "@/services/PostDataServices";

//-----VUE
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

//-----QUASAR
import { date } from "quasar";

export default {
  name: "PostCard",
  props: {
    image: {
      type: String,
      default: "",
    },
    post: Object,
  },
  components: {
    FilterButtons,
    RoundedBtn,
    SectionComents,
    CarouselPosts,
    UserPhoto,
    DialogAnalytics,
  },
  emits: ["disabled", "fixPost", "favoritePost", "edit"],

  setup(props, {emit}) {
    //----CONSTANTS
    const store = useStore();
    const canUserAccessClass = new CanUserAccessClass();
    const filterListItens = ref([
      { name: "Parabéns", value: "Parabéns", active: true },
      { name: "Que legal", value: "Que legal" , active: true},
      { name: "OK", value: "OK", active: true },
      { name: "Entendi", value: "Entendi", active: true },
    ]);
    const postDataServices = new PostDataServices();

    //----VARIABLES
    let isShowComents = ref(false);
    let isShowDatePicker = ref(false);
    let datePostFixed = ref(Date.now());
    let AwnserSeleceted = ref("");
    let visibleAnalytics = ref(false);
    let user = JSON.parse(localStorage.getItem("vuex")).userdata;
    let number_total_views = ref(0);
    let btnFixed = ref(true);

    onMounted(() => {
      btnFixed.value = validateActionsPage("new-trail");
    });

    const datePost = computed(() => {
      const timeStamp = Date.now();
      const formattedCurrentTime = date.formatDate(
        timeStamp,
        "YYYY-MM-DDTHH:mm:ss"
      );
      const formattedpostDate = date.formatDate(
        props.post.created_at,
        "DD/MM/YYYY H:mm"
      );
      const formattedSchedulleDate = date.formatDate(
        props.post.datetime_Start,
        "DD/MM/YYYY H:mm"
      );

      if (formattedCurrentTime <= props.post.datetime_Start) {
        return `Agendado para ${formattedSchedulleDate}`;
      } else {
        return `Criado em ${formattedpostDate}`;
      }
    });

    async function showComents() {
      isShowComents.value = !isShowComents.value;
    }

    function showDatePicker() {
      if (props.post.is_fixed){
        emit('fixPost', props.post, datePostFixed)
        return
      }
      isShowDatePicker.value = !isShowDatePicker.value;
    }

    async function updateProgress() {
      let userdata = store.state.userdata;

      let userIndicators = await UpdateXpUser();

      console.log(userIndicators)

      userdata.progress = userIndicators.progress;
      userdata.class_name = userIndicators.class_name
      userdata.leve_name = userIndicators.level_name;

      store.commit("setUserData", userdata);
    }

    function getItemSelected(value) {
      AwnserSeleceted.value = value;
    }

    function toggle() {
      const target = event.target.parentNode.parentNode.parentNode;
      this.$q.fullscreen
        .toggle(target)
        .then(() => {})
        .catch((err) => {
          alert(err);
        });
    }

    function addLike(post) {
      if (!post.isLiked) {
        post.likes = parseInt(post.likes) + 1;
        post.isLiked = true;
        AddLikeInApi("like", post.postId);
      } else {
        post.likes = parseInt(post.likes) - 1;
        post.isLiked = false;
        AddLikeInApi("deslike", post.postId);
      }
    }

    function AddLikeInApi(type, postId) {
      var data = {
        type: type,
        commentId: 0,
      };
      postDataServices.likeOrDeslike(postId, data).then(() => updateProgress());
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    function validateModifyPost(post) {
      let permissionValidate = canUserAccessClass.canUserAccess(
        "add-post",
        false
      );

      if (permissionValidate && post.userId == user.id) {
        return true;
      } else if (permissionValidate && post.edition_other_tutor) {
        return true;
      } else if (permissionValidate && user.role_id == 1) {
        return true;
      } else {
        return false;
      }
    }

    function addDescriptionPost(text) {
      var urlRegex =
        /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
      return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '">' + url + "</a>";
      });
    }
    function analytics() {
      visibleAnalytics.value = !visibleAnalytics.value;
    }

    async function viewCount() {
      let response = await postDataServices.addViewPost(props.post.postId);
    }

    return {
      //----CONSTANTS
      user,
      //----VARIABLES
      isShowComents,
      isShowDatePicker,
      datePostFixed,
      filterListItens,
      url_aws_bucket: ENV.URL_AWS_BUCKET,
      AwnserSeleceted,
      visibleAnalytics,
      number_total_views,
      datePost,
      btnFixed,
      //----FUNCTIONS
      showComents,
      AddLikeInApi,
      addLike,
      toggle,
      showDatePicker,
      getItemSelected,
      addDescriptionPost,
      analytics,
      validateModifyPost,
      viewCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  width: 100%;
  /* height: 100%; */
  /* min-height: 80vh; */
  /* max-height: 162px; */

  margin-bottom: 1rem;

  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
}

.header {
  display: flex;
  flex-direction: row;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    //#a1cb72
    background-color: var(--q-secundary);
    position: absolute;
    border-radius: 0px 2px 2px 0px;
    top: 18%;
  }

  .avatar-notification {
    width: 35px;
    height: 35px;
    margin: 0 2%;
    overflow: hidden;
    border-radius: 35px;
    position: relative;
  }

  .title-notification {
    flex: 0.98;
    //width: 80%;

    h6 {
      margin: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 17px;
    }

    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #0000004d;
    }
  }
}

.midia-box {
  padding: 0.5rem;
  //max-height: 300px;
}

.description {
  padding: 0 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 16px;
  color: #0009;
}

.post-banner {
  .post-info-btn {
    height: 83%;
    position: absolute;
    bottom: 0;
  }
}

.section-coments {
  width: 100%;
  height: 65vh;
  overflow-y: auto;
}

.button-coments {
  height: 15%;
  width: 100%;

  overflow-x: auto;
}

.my-card {
  width: 700px;
  max-width: 80vw;
  text-align: center;

  > label {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
  }

  .cards-date {
    display: flex;
    justify-content: space-evenly;
  }
}

.card-user-profile {
  position: absolute;
  width: 150px;
  height: 150px;
}
</style>
